import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GREEN, WHITE, OBERMAIN_BORDER, OBERMAIN_BLACK,
} from '@theme_color';
import {
    FlexColumn,
    CreateBorder,
    Centering,
} from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        background: WHITE,
        width: '100%',
        height: 'auto',
        padding: 20,
        ...FlexColumn,
        position: 'sticky',
        top: 100,
        borderRadius: 3,
        boxShadow: '0px 6px 14px rgba(0,0,0,0.1)',
        '& >h1': {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 15,
            textTransform: 'capitalize',
            fontSize: 16,
            fontWeight: '700',
        },
        '& .items-in-cart-wrapper': {
            marginBottom: 15,
            cursor: 'pointer',
            '& .items-in-cart': {
                '& span': {
                    fontSize: 14,
                    margin: '5px 0',
                },
                '& +.arrow-items': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                },
            },
        },
        '& .wrapper-items': {
            marginLeft: 0,
            marginRight: 0,
            '& #listItemProductSummary': {
                '&:first-child': {
                    '& >div': {
                        borderTop: `1px solid ${OBERMAIN_BORDER}`,
                    },
                },
                '& >div': {
                    borderTop: 0,
                    '& .product-info': {
                        '& .MuiTypography-root': {
                            marginLeft: 0,
                            marginRight: 0,
                            fontSize: 14,
                            lineHeight: '1.2',
                            marginBottom: 5,
                        },
                        '& .qty-delete': {
                            display: 'flex',
                            alignItems: 'center',
                            '& .wrapper-qty': {
                                display: 'flex',
                                alignItems: 'center',
                                '& .item-count': {
                                    height: 18,
                                    minWidth: 40,
                                    display: 'inline-block',
                                    textAlign: 'center',
                                    fontWeight: '500',
                                },
                                '& .qty-update': {
                                    border: `1px solid ${OBERMAIN_BLACK}`,
                                    borderRadius: 3,
                                    height: 18,
                                    width: 18,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:after': {
                                        fontSize: 16,
                                        lineHeight: 'normal',
                                    },
                                },
                            },
                            '& .delete': {
                                position: 'static',
                                height: 'auto',
                                marginLeft: 20,
                                fontSize: 0,
                                width: 'auto',
                                '& i': {
                                    fontSize: 18,
                                },
                            },
                        },
                    },
                },
            },
        },
        '& .list-total': {
            borderTop: `1px solid ${OBERMAIN_BORDER}`,
            '& >li': {
                display: 'flex',
                alignItems: 'center',
                '& .listSummary': {
                    borderTop: '0 transparent',
                    padding: 0,
                },
                '& .MuiListItemSecondaryAction-root': {
                    position: 'static',
                    transform: 'unset',
                },
                '& .MuiTypography-root': {
                    fontSize: 14,
                    margin: '2px 0',
                },
                '&:last-child': {
                    borderTop: `1px solid ${OBERMAIN_BORDER}`,
                    marginTop: 10,
                    paddingTop: 10,
                },
            },
        },
    },
    list: {
        ...CreateBorder('1px', 0, 0, 0, OBERMAIN_BORDER),
    },
    footer: {
        width: '100%',
        ...FlexColumn,
        alignItems: 'center',
    },
    btnCheckout: {
        marginTop: 20,
        minWidth: '90%',
        marginBottom: 20,
    },
    paypalBtn: {
        minWidth: '90%',
    },
    labelItem: {
        maxWidth: '50%',
    },
    expanItem: {
        background: 'transparent',
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
    },

    listProduct: {
        padding: 20,
        position: 'relative',
        '& .delete': {
            position: 'absolute',
            top: 22,
            right: 0,
            fontSize: 15,
            cursor: 'pointer',
            width: 10,
            height: 10,
        },
    },
    imgProduct: {
        width: 75,
        height: 'auto',
    },
    imgBox: {
        position: 'relative',
    },
    freeItem: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        minWidth: 15,
        minHeight: 10,
        backgroundColor: GREEN,
        color: WHITE,
        fontWeight: '700',
        fontSize: 8,
        padding: 2,
        borderRadius: 3,
        ...Centering,
        marginLeft: 'auto',
        marginRight: 5,
        textTransform: 'uppercase',
    },
    bodyProductItem: {
        ...FlexColumn,
        '& .item-minus': {
            cursor: 'pointer',
            '&::after': {
                content: '"-"',
            },
        },
        '& .item-count': {
            padding: '0px 10px',
        },
        '& .item-plus': {
            cursor: 'pointer',
            '&::after': {
                content: '"+"',
            },
        },
    },
}));

export default useStyles;
