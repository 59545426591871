import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_PRIMARY, PRIMARY, OBERMAIN_BORDER, OBERMAIN_BLACK,
} from '@theme_color';
import {
    Centering,
    CreateBorder,
    CreatePadding,
    FlexColumn,
    FlexRow,
    CreateMargin,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    root: {
        ...FlexColumn,
        height: '100vh',
        width: '100%',
    },
    container: {
        width: '100%',
        height: 'auto',
        paddingBottom: 150,
    },
    block: {
        ...CreatePadding(20, 0, 20, 0),
        '& h1': {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 15,
            textTransform: 'capitalize',
        },
        '&#checkoutDeliveryMethod': {
            '& .row': {
                '& .item-delivery': {
                    '& > div': {
                        borderRadius: '3px',
                        border: `1px solid ${OBERMAIN_BORDER}`,
                        '&.active': {
                            border: `1px solid ${OBERMAIN_BLACK}`,
                        },
                        '& .column': {
                            '& span': {
                                fontSize: 14,
                                margin: 0,
                            },
                        },
                    },
                },
            },
        },
        '&#checkoutPickupStore': {
            '& >div': {
                borderRadius: '3px',
                border: `1px solid ${OBERMAIN_BORDER}`,
            },
            '& >.MuiTypography-root': {
                fontSize: 14,
            },
        },
        '&#checkoutEmailSetup': {
            '& >div': {
                marginLeft: 0,
                marginRight: 0,
                '& .MuiFormControl-root': {
                    marginTop: '0 !important',
                    marginBottom: '0 !important',
                    '& .MuiInputBase-root': {
                        marginTop: '0 !important',
                    },
                },
            },
        },
        '&#checkoutAddress': {
            '& .address-container': {
                padding: 20,
                border: `1px solid ${OBERMAIN_BORDER}`,
                borderRadius: 3,
                [theme.breakpoints.down('xs')]: {
                    flexWrap: 'wrap',
                    '& >div': {
                        width: '100%',
                        '& .MuiButtonBase-root': {
                            width: '100%',
                            marginTop: 20,
                        },
                    },
                },
                '& .address-content': {
                    fontSize: 14,
                    margin: 0,
                    '& .address-details': {
                        '& p': {
                            margin: 0,
                            lineHeight: 1.7,
                            '&.name': {
                                fontWeight: '700',
                            },
                        },
                    },
                },
                '& .alert-empty-pin-point': {
                    height: 'auto',
                },
            },
        },
        '&#checkoutShipping': {
            '& >p.MuiTypography-root': {
                fontSize: 14,
                marginLeft: 0,
                marginRight: 0,
            },
            '& .MuiAccordion-root': {
                border: `1px solid ${OBERMAIN_BORDER}`,
                marginBottom: '-1px',
                '&:first-child': {
                    borderTopLeftRadius: '3px',
                    borderTopRightRadius: '3px',
                },
                '&:last-child': {
                    borderBottomLeftRadius: '3px',
                    borderBottomRightRadius: '3px',
                },
                '& .MuiAccordionSummary-root': {
                    borderBottom: `1px solid ${OBERMAIN_BORDER}`,
                    '& .MuiAccordionSummary-content': {
                        '& span': {
                            textTransform: 'capitalize',
                            fontSize: 14,
                        },
                    },
                    '& .MuiIconButton-root': {
                        marginRight: '-7px',
                        '& .MuiIconButton-label': {
                            '& >svg': {
                                display: 'none',
                            },
                            '&:after': {
                                content: '""',
                                width: 9,
                                height: 9,
                                borderRadius: 1,
                                borderLeft: `2px solid ${OBERMAIN_BLACK}`,
                                borderBottom: `2px solid ${OBERMAIN_BLACK}`,
                                transform: 'rotate(-45deg)',
                            },
                        },
                    },
                },
            },
            '& #checkoutRadioItem': {
                cursor: 'pointer',
                '& p.MuiTypography-root': {
                    fontSize: 14,
                },
            },
        },
        '&.fee-and-promo': {
            paddingBottom: 0,
            '& .box-item': {
                '& >div': {
                    padding: 0,
                    '& >div': {
                        marginBottom: 0,
                    },
                    '& .MuiFormControl-root': {
                        marginTop: '5px !important',
                    },
                    '& .MuiButton-root': {
                        height: 40,
                    },
                },
            },
        },
        '&#checkoutPayment': {
            '& h1 + div .MuiButton-root': {
                display: 'none',
            },
            '& >div>div>p': {
                display: 'none',
            },
            '& .MuiAccordion-root': {
                border: `1px solid ${OBERMAIN_BORDER}`,
                marginBottom: '-1px',
                '&:first-child': {
                    borderTopLeftRadius: '3px',
                    borderTopRightRadius: '3px',
                },
                '&:last-child': {
                    borderBottomLeftRadius: '3px',
                    borderBottomRightRadius: '3px',
                },
                '& .MuiAccordionSummary-root': {
                    borderBottom: `1px solid ${OBERMAIN_BORDER}`,
                    '& >.MuiAccordionSummary-content': {
                        '& >span': {
                            textTransform: 'capitalize',
                            fontSize: 14,
                        },
                    },
                    '& .MuiIconButton-root': {
                        marginRight: '-7px',
                        '& .MuiIconButton-label': {
                            '& >svg': {
                                display: 'none',
                            },
                            '&:after': {
                                content: '""',
                                width: 9,
                                height: 9,
                                borderRadius: 1,
                                borderLeft: `2px solid ${OBERMAIN_BLACK}`,
                                borderBottom: `2px solid ${OBERMAIN_BLACK}`,
                                transform: 'rotate(-45deg)',
                            },
                        },
                    },
                },
            },
            '& #checkoutRadioItem': {
                cursor: 'pointer',
                '& p.MuiTypography-root': {
                    fontSize: 14,
                },
            },
        },
    },
    addressContainer: {
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    addressText: {
        ...FlexColumn,
        maxWidth: '60%',
    },
    listShipping: {
        ...CreateBorder('1px', 0, 0, 0, PRIMARY),
    },
    listShippingGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        boxSizing: 'inherit',
    },
    radioShiping: {
        width: '100% !important',
    },
    btnPoint: {
        maxWidth: 140,
        ...Centering,
        padding: 5,
    },
    cardPoint: {
        ...CreateMargin(5, 0, 5, 0),
        ...CreatePadding(17, 17, 17, 17),
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
        ...CreateBorder('1px', '1px', '1px', '1px', GRAY_PRIMARY),
        borderRadius: 10,
        maxWidth: 480,
    },
    pointText: {
        fontSize: 20,
        ...CreateMargin(0, 0, 0, 5),
    },
    btnBalanceGift: {
        ...CreateMargin(-25, 0, 0, -5),
    },
    rmBorder: {
        border: 'none',
    },
    smallCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -8,
    },
    mediumCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    largeCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -15,
        marginLeft: -15,
    },
    customFormControl: {
        marginTop: '10px',
        marginBottom: '20px',
    },
    emailContainer: {
        margin: '5px',
    },
    giftCardContainer: {
        position: 'relative',
    },
    giftcardInfoContainer: {
        ...CreateMargin(-30, 10, 30, 5),
    },
    giftCard: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    paymentExpansionContainer: {
        marginTop: '10px',
    },
    placeOrderDesktop: {
        maxWidth: 230,
        height: 50,
    },
    labelAccordion: {
        ...FlexRow,
        alignItems: 'center',
    },
    mobileBottomSpace: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '150px !important',
        },
        '&.wrapper-checkout-obermain': {
            width: '100%',
            maxWidth: '1280px',
            margin: 'auto',
        },
        '& #header': {
            height: 120,
            '& > div': {
                lineHeight: 0,
                '& img': {
                    height: 25,
                },
            },
        },
        '& .summary-sidebar': {
            [theme.breakpoints.up('sm')]: {
                flexBasis: '30% !important',
                maxWidth: '30% !important',
            },
        },
    },
    paymentHeader: {
        ...FlexRow,
        justifyContent: 'space-between',
    },
    howToPay: {
        ...CreatePadding(5, 15, 5, 15),
    },
}));
